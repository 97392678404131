<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <h4>Social Capital Score</h4>
        <p>
          Your score is based on verified data and gives insights into your
          reach influence and potential in various sectors. By improving your
          Social Capital Score, you enhance your ability to make a positive
          impact.
        </p>
        <p>
          Increase the accuracy of your Social Capital Score by completing this
          quick <a href="#">Seld Assessment</a>
        </p>
      </div>
    </div>
  </div>

  <div class="container mb-5">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6">
            <img
              src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto/v1492603822/commonshare/mchangawide.png"
            />
          </div>
          <div class="col-md-6">
            <h5>Boost your Social Capital</h5>
            <ul>
              <li>Grow Your Network</li>
              <li>Enhance Your Skills</li>
              <li>Discove New Tools</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      cards: [
        {
          image:
            "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117692/web/article-3.jpg",
          title: "M-Changa",
          subText: "29 subscribers",
          text1: "M-CHANGA: FUNDRAISING SIMPLE: FAST: SECURE.",
          text2: "",
        },
      ],
    };
  },
};
</script>


<style scoped></style>